<template>
	<v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Calculo de aguinaldos</span>
            <v-spacer></v-spacer>

            <v-btn
              class="mr-2"
              small
              dark
              color="green"
              @click="exportarDataExcel()"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

          </v-card-title>
          <v-card-text>          
            <v-row >

              <v-col cols="12" md="4" lg="3">
                <v-autocomplete
                  :items="puestos"
                  v-model="puesto_seleccionado"
                  label="Puesto"
                  clearable
                  filled
                  dense
                  item-text="puesto"
                  item-value="idpuesto"
                  single-line
                  hide-details
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-autocomplete
                  :items="estatusAll"
                  v-model="estatus"
                  label="estatu"
                  clearable
                  filled
                  dense
                  item-text="estatus"
                  item-value="idestatus"
                  single-line
                  hide-details
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="6" lg="5">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
            	<v-col cols="12" class="black--text text-h6">
            		<b>TOTAL:  {{ this.format( total ) }}</b> 
            	</v-col>
            	<v-col cols="12" class="black--text">
            		<b>Total:     {{  empleadosTotal  }}</b> <br/>
            		<b>Firmados:  {{  firmados  }}</b> <br/>
            		<b>Faltan:    {{  faltan  }}</b> <br/>
            		<b>Avance:    %{{  avance  }}</b> <br/>
            	</v-col>
            </v-row>
            <v-row>

              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="filterTrabajadores"
                  class="elevation-0"
                  :search="search"
                  :mobile-breakpoint="100"
                  dense
                >

                  <template v-slot:item.sueldo_mensual="{ item }">
                    {{ item.sueldo_mensual ? format( item.sueldo_mensual ) : 'NA' }}
                  </template>

                  <template v-slot:item.sueldo_semanal="{ item }">
                    {{ item.sueldo_semanal ? format( item.sueldo_semanal ) : 'NA' }}
                  </template>

                  <template v-slot:item.sueldo_dia="{ item }">
                    {{ item.sueldo_dia ? format( item.sueldo_dia ) : 'NA' }}
                  </template>

                  <template v-slot:item.aguinaldo="{ item }">
                    {{ item.aguinaldo ? format( item.aguinaldo ) : 'NA' }}
                  </template>

                  <template v-slot:item.estatus="{ item }">
                    <v-chip 
                    	small 
                    	:color="item.estatus ? 'green': 'red'"
                    	dark
                    >
                  		{{ item.estatus ? 'Firmado': 'Sin Firma' }}
                  	</v-chip>
                  </template>

                  <template v-slot:item.comprobante="{ item }">
                    <v-icon v-if="item.comprobante" @click="verComprobante( item )">mdi-clipboard-check</v-icon>
                  </template>


                  <template v-slot:item.actions="{ item }">
                    <v-btn 
                      color="primary" 
                      x-small
                      tile
                      @click="editItem(item)"
                      class="mr-2"
                    >
                      <v-icon small>mdi-magnify</v-icon>
                    </v-btn>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogo de editar-->
    <v-dialog
      v-model="dialog_editar"
      max-width="1000px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle2 }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
           <strong>ID: {{ editedItem.id_trabajador }}</strong>
         </span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.nombres"
                label="Nombre(s)"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.apellido_paterno"
                label="Apellido Paterno"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.apellido_materno"
                label="Apellido Materno"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.domicilio"
                label="Domicilio"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">   
              <v-text-field 
                v-model="editedItem.horario_ingreso" 
                label="Hora ingreso:" 
                dense 
                type="time"
                filled
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">   
              <v-text-field 
                v-model="editedItem.horario_salida" 
                label="Hora salida:" 
                dense 
                type="time"
                filled
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">   
              <v-text-field 
                v-model="editedItem.horario_ingreso_fin" 
                label="Hora ingreso Fin:" 
                dense 
                type="time"
                filled
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">   
              <v-text-field 
                v-model="editedItem.horario_salida_fin" 
                label="Hora salida Fin:" 
                dense 
                type="time"
                filled
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
              v-model="editedItem.telefono1"
              label="Telefono"
              filled
              hide-details
              dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
              v-model="editedItem.telefono2"
              label="Celular"
              filled
              hide-details
              dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.fecha_ingreso"
                label="Fecha Ingreso"
                filled
                hide-details
                dense
                type="date"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.fecha_termino_labores"
                label="Fecha Termino Labores"
                filled
                type="date"
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
              :items="planteles"
              v-model="editedItem.id_plantel"
              label="Plantel"
              filled
              hide-details
              dense
              item-text="plantel"
              item-value="id_plantel"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.fecha_nacimiento"
                label="Fecha Nacimiento"
                filled
                hide-details
                dense
                type="date"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.idpuesto"
                :items="puestos"
                label="Selecciona un puesto"
                persistent-hint
                item-text="puesto"
                item-value="idpuesto"
                prepend-inner-icon="mdi-account-box"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.dia_descanso"
                :items="[
                  { dia_descanso: 1, nombre: 'Sábado' },
                  { dia_descanso: 2, nombre: 'Domingo'},
                  { dia_descanso: 3, nombre: 'Lunes' },  
                  { dia_descanso: 4, nombre: 'Martes' },  
                  { dia_descanso: 5, nombre: 'Miercoles' },  
                  { dia_descanso: 6, nombre: 'Jueves' },  
                  { dia_descanso: 7, nombre: 'Viernes' }       
                ]"
                label="Selecciona el día de descanso"
                persistent-hint
                item-text="nombre"
                item-value="dia_descanso"
                prepend-inner-icon="mdi-account-box"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.idsexo"
                :items="[
                  { idsexo: 1, sexo: 'Masculino'},
                  { idsexo: 2, sexo: 'Femenino'},
                  { idsexo: 3, sexo: 'Otro'},   
                ]"
                label="Selecciona el sexo"
                persistent-hint
                item-text="sexo"
                item-value="idsexo"
                prepend-inner-icon="mdi-account-box"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                v-if="editedItem.idpuesto == 18 || editedItem.idpuesto == 19"
                filled
                dense
                clearable
                v-model="editedItem.idencargada_experiencia"
                :items="[{idencargada_experiencia: 1, encargada_experiencia: 'Principiante'},
                         {idencargada_experiencia: 2, encargada_experiencia:'Avanzada'}]"
                label="Selecciona la experiencia"
                persistent-hint
                item-text="encargada_experiencia"
                item-value="idencargada_experiencia"
                prepend-inner-icon="mdi-account-box"
                hide-details
                
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.horas_totales"
                label="Horas Trabajadas Total"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

           <v-col cols="12" md="6">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.nuevo_id_usuario"
                :items="usuarios"
                label="Selecciona un usuario"
                persistent-hint
                prepend-inner-icon="mdi-account"
                hide-details
                item-text="nombre_completo"
                item-value="id_usuario"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43, 31,68].includes( getdatosUsuario.idpuesto ) && editedItem.idpuesto == 10">
              <v-select
                :items="categorias"
                v-model="editedItem.idcategorias_maestro"
                label="Categoría"
                hide-details
                dense
                filled
                item-text="categoria"
                item-value="idcategorias_maestro"
                
              ></v-select>
            </v-col>

            <v-col cols="12" md="6" v-if="editedItem.idpuesto == 10">
              <v-select
                :items="disponibilidades"
                v-model="editedItem.iddisponibilidad_maestros"
                label="Disponibilidad"
                hide-details
                dense
                filled
                item-text="disponibilidad"
                item-value="iddisponibilidad_maestros"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.cuenta_hsbc"
                label="Cuenta HSBC"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.numero_tarjeta"
                label="Número Tarjeta"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.tarjeta_secundaria"
                label="Número Tarjeta secundaria"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.banco"
                label="Banco"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.folio_banco"
                label="Folio Banco"
                filled
                hide-details
                dense
                type="integer"
              ></v-text-field>
            </v-col>
            
            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto )">
              <v-select
                :items="[{id: 1, text: 'Día'},{id: 2, text:'Hora'}]"
                v-model="editedItem.tipo_pago"
                label="Tipo de pago"
                hide-details
                dense
                filled
                item-text="text"
                item-value="id"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto ) && editedItem.tipo_pago == 1">
              <v-autocomplete
                v-if="editedItem.idpuesto == 19 "
                :items="sueldos"
                v-model="editedItem.idsueldo_recepcion"
                label="Sueldo Mensual"
                dense
                filled
                item-value="idsueldo_recepcion"
                item-text="sueldo_mensual_texto"
                
                hide-details
              ></v-autocomplete>
              <v-text-field
                v-else
                v-model="editedItem.sueldo_mensual"
                label="Sueldo Mensual"
                filled
                hide-details
                dense
                
                auto-grow
              ></v-text-field>
            </v-col>
            
            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto ) && editedItem.tipo_pago == 1">
              <v-text-field
                v-model="editedItem.factor"
                label="Factor"
                filled
                
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto ) && editedItem.tipo_pago == 1">
              <v-text-field
                v-model="editedItem.sueldo_dia"
                label="Sueldo Día"
                filled
                hide-details
                
                dense
                
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto )">
              <v-text-field
                v-model="editedItem.sueldo_hora"
                label="Sueldo Hora"
                
                filled
                hide-details
                dense
                :readonly="editedItem.tipo_pago == 1 ? true : false "
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto ) && editedItem.idpuesto == 10">
              <v-text-field
                v-model="editedItem.sueldo_hora_2"
                label="Sueldo Hora 2"
                
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto ) && editedItem.idpuesto == 10">
              <v-text-field
                v-model="editedItem.sueldo_hora_3"
                label="Sueldo Hora 3"
                filled
                
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="[19,18,73].includes( editedItem.idpuesto )">
              <v-select
                :items="categoriasVendedoras"
                v-model="editedItem.idcategorias_encargadas"
                label="Tipo de pago"
                hide-details
                dense
                
                filled
                item-text="categoria"
                item-value="idcategorias_encargadas"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                :items="vacantes"
                v-model="editedItem.idseguimiento_campanias"
                label="Vacante"
                clearable
                filled
                dense
                item-text="vacante"
                item-value="idseguimiento_campanias"
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-radio-group
                v-model="editedItem.prestaciones"
                row
              >
                <v-radio
                  label="Con prestaciones"
                  :value="1"
                ></v-radio>
                <v-radio
                  label="Sin prestaciones"
                  :value="2"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" md="6" v-if="editedItem.prestaciones == 1">
              <v-text-field
                v-model="editedItem.rebaje_prestaciones"
                label="Rebaje IMSS"
                filled
                type="number"
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="editedItem.prestaciones == 1">
              <v-file-input
                accept=".pdf"
                placeholder="Selecciona la alta"
                prepend-icon="mdi-file"
                label="Selecciona la alta"
                v-model="files"
                filled
              ></v-file-input>

              <embed :src="url + editedItem.url_alta_imss" type="application/pdf" width="100%" height="600px" v-if="editedItem.url_alta_imss && editedItem.prestaciones == 1"/>

            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="editedItem.motivo_salida"
                label="Motivo Salida"
                outlined
                hide-details
                dense
                auto-grow
              ></v-textarea>
            </v-col>

            <v-col cols="12">
              <label>Disponibilidad L, M, M</label>
              <v-row>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Día" v-model="editedItem.lm_d" :value="1"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Tarde" v-model="editedItem.lm_t" :value="1"></v-checkbox>
                </v-col>
              </v-row>

              <label>Disponibilidad J, V</label>
              <v-row>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Día" v-model="editedItem.jv_d" :value="1"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Tarde" v-model="editedItem.jv_t" :value="1"></v-checkbox>
                </v-col>
              </v-row>

              <label>Disponibilidad Sábado</label>
              <v-row>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Día" v-model="editedItem.s_d" :value="1"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Tarde" v-model="editedItem.s_t" :value="1"></v-checkbox>
                </v-col>
              </v-row>

              <label>Disponibilidad Domingo</label>
              <v-row>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Día" v-model="editedItem.d_d" :value="1"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Tarde" v-model="editedItem.d_t" :value="1"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="6" class="pt-0" v-if="editedItem.idpuesto == 10">
              <v-switch 
                label="Apoyando Si/No?"
                hide-details
                v-model="editedItem.apoyando">
              </v-switch>
            </v-col>

            <v-col cols="12" sm="6" class="pt-0">
              <v-switch 
                label="¿Activo Si/No?"
                hide-details
                v-model="editedItem.activo_sn">
              </v-switch>
            </v-col>

            <v-col cols="12" sm="6" class="pt-5">
              <div class="text-subtitle-1"><b>Fecha alta:</b> {{ editedItem.fecha_alta }}</div>
              <div class="text-subtitle-1"><b>Fecha baja:</b> {{ editedItem.fecha_baja }}</div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close()"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row justify="space-around">
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
                    <!-- Guardar la información  -->
                    <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-img src="@/assets/borrar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- VER PDF -->
    <v-dialog v-model="dialogPDF" max-width="650px">
      <v-card class="elevation-0">
        <!-- Imagen -->
        <embed :src="`https://escuelakpi.club/viewer/web/viewer.html?file=https://escuelakpi.club/imagenes-aguinaldo/firma-${editedItem.id}.pdf`" 
        	width="100%" 
          :height="tamanioPantalla" 
        >
        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <!-- Guardar la información  -->
          <v-btn
            color="error" 
            dark 
            class="elevation-6" 
            block
            @click="dialogPDF = false"
          >Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

    components:{
      Alerta,
      carga,
    },

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      activos: null,
      puestos:[],
      puesto_seleccionado: null,
      plantel: null,
      planteles:[],
      perfiles:[],
      puestos:[],
      jornada:[],
      tipo_trabajador:[],
      nivel:[],
      curso:[],
      trabajadores:[],
      trabajadorERP:[],
      usua:[],
      departamento:[],
      valor:0,

      fecha_nacimiento:0,
      fecha_termino_labores:null,
      fecha_ingreso:0,

      dialog_agregar: false,
      dialog_editar: false,
      dialogDelete: false,
      dialog: false,
      
      editedIndex: -1,

      dialogSueldos: {
        estatus: false
      },

      editedItem: {
        nombres:'',                 
        apellido_paterno:'',           
        apellido_materno:'',           
        domicilio:'',                  
        horario_inicio: null,             
        horario_salida: null,             
        horario_inicio_fin: null,             
        horario_salida_fin: null,      
        telefono1:'',                  
        telefono2:'',                  
        email:'',                      
        rfc:'',                        
        numero_poliza_sgm_mayores:'',  
        numero_poliza_sgm_menores:'',  
        numero_imss:'',                
        fecha_ingreso: null,              
        fecha_termino_labores: null,      
        antiguedad:'',                 
        id_plantel:null,               
        idpuesto:null,   
        dia_descanso:0, 
        idsexo: null,  
        idencargada_experiencia:null,  
        horas_totales:null,             
        id_jornada_laboral:null,        
        id_departamento:null,           
        id_tipos_trabajadores:null,      
        id_nivel:null,                   
        id_curso:null,                   
        fecha_nacimiento:null,           
        motivo_salida:'',             
        activo_sn:'',                  
        id:null,
        numero_tarjeta: 0,
        banco: 0,
        folio_banco: 0,
        factor: 0,
        sueldo_hora_2:0,
        sueldo_hora_3:0,
        sueldo_hora:  0,
        apoyando: 0,
        nuevo_id_usuario: 0,
        idcategorias_maestro: 0,
        iddisponibilidad_maestros: 0,
        rolado_ciclo: false,
        lm_d: 0,
        lm_t:0,
        jv_d:0,
        jv_t:0,
        s_d:0,
        s_t: 0,
        d_d:0,
        d_t:0,
        numero_tarjeta_anterior:0,
        idcategorias_maestro_anterior:0,
        tarjeta_secundaria:'',
        idcategorias_encargadas:0,
        cuenta_hsbc:0,
        idsueldo_recepcion:0,
        idseguimiento_campanias:0,
      },

      defaultItem: {
        nombres:'',                 
        apellido_paterno:'',           
        apellido_materno:'',           
        domicilio:'',                  
        horario_inicio: null,             
        horario_salida: null,  
        horario_inicio_fin: null,             
        horario_salida_fin: null,                
        telefono1:'',                  
        telefono2:'',                  
        email:'',                      
        rfc:'',                        
        numero_poliza_sgm_mayores:'',  
        numero_poliza_sgm_menores:'',  
        numero_imss:'',                
        fecha_ingreso: null,              
        fecha_termino_labores: null,      
        antiguedad:'',                 
        id_plantel:null,               
        idpuesto:0,     
        dia_descanso:0,
        idsexo: null,
        idencargada_experiencia:null,     
        horas_totales:null,            
        id_jornada_laboral:0,        
        id_departamento:0 ,          
        id_tipos_trabajadore: 0,      
        id_nivel:0,                   
        id_curso:0,                   
        fecha_nacimiento:null,           
        motivo_salida:'',             
        activo_sn:'',                  
        id:null,
        numero_tarjeta: 0,
        banco: 0,
        folio_banco: 0,
        factor: 0,
        sueldo_hora_2:0,
        sueldo_hora_3:0,
        sueldo_hora:  0,
        apoyando: 0,
        nuevo_id_usuario: 0,
        idcategorias_maestro: 0,
        iddisponibilidad_maestros: 0,
        rolado_ciclo: false,
        lm_d: 0,
        lm_t:0,
        jv_d:0,
        jv_t:0,
        s_d:0,
        s_t: 0,
        d_d:0,
        d_t:0,
        numero_tarjeta_anterior:0,
        idcategorias_maestro_anterior:0,
        tarjeta_secundaria:'',
        idcategorias_encargadas:0,
        cuenta_hsbc:0,
        idsueldo_recepcion:0,
        idseguimiento_campanias:0,
        prestaciones: 0,
        rebaje_prestaciones:0,
        url_alta_imss:''
      },

      categoriasVendedoras:[],

      // Filtros: 
      incio:null,
      fin:null,

      // tabla
	     search:'',
	     page: 1,
	     pageCount: 0,
	     itemsPerPage: 10,
	     horarios: [],
	     headers: [
	      { text: 'ID'                    , value: 'id'              },
	      { text: 'Trabajador'            , value: 'nombre_completo' },
	      { text: 'Puesto'                , value: 'puesto'          },
	      { text: 'Depto'                 , value: 'departamento'    },
	      { text: 'Fecha ingreso'         , value: 'fecha_ingreso'   },
	      { text: 'Cierre Año'            , value: 'cierre_anio'     },
	      { text: 'Días antigüedad'       , value: 'antiguedad'      },
	      { text: 'Sueldo Mensual'        , value: 'sueldo_mensual'  },
	      { text: 'Sueldo Semanal'        , value: 'sueldo_semanal'  },
	      { text: 'Sueldo Diario'         , value: 'sueldo_dia'      },
	      { text: 'Días aguinaldo'        , value: 'dias_aguinaldo'  },
	      { text: 'Aguinaldo'             , value: 'aguinaldo'       },
	      { text: 'Estatus'               , value: 'estatus'         },
	      { text: 'Comprobante'           , value: 'comprobante'     },
	    ],

      usuarios: [],
      disponibilidades:[],
      categorias:[],
      sueldos: [],
      vacantes:[],
      reglamento: false,
      descriptor: false,
      misionvision: false,
      confidencialidad: false,
      dialogVerDocumentos: false,
      files:'',

      url:'',

      estatusAll:[
      	{ idestatus: 1, estatus: 'Firmado' },
      	{ idestatus: 2, estatus: 'Sin firma' },
      	{ idestatus: 3, estatus: 'Todo' },
      ],
			estatus: 3,

			dialogPDF: false,

    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),

      formTitle () {
        return 'Agregar Usuario'
      },

      formTitle2 () {
        return 'Editar Usuario'
      },

      filterTrabajadores( ){
        let data = this.trabajadorERP

        if( this.plantel ){
          data = data.filter( el=> { return el.id_plantel == this.plantel })
        }

        if( this.puesto_seleccionado ){
          data = data.filter( el=> { return el.idpuesto == this.puesto_seleccionado })
        }

        if( this.estatus != 3 ){

        	if( this.estatus == 1 ){
          	data = data.filter( el=>  el.estatus )
        	}else{
          	data = data.filter( el=>  !el.estatus )
        	}
        }

        return data 
      },

      total( ){

      	return this.filterTrabajadores.filter( el => el.aguinaldo ).map(item => item.aguinaldo).reduce((prev, curr) => prev + curr, 0)

      },


      avance( ){


      	let trabajadores = this.filterTrabajadores.length
      	let firmados     = this.filterTrabajadores.filter( el => el.estatus ).length


      	return (( firmados / trabajadores ) * 100 ).toFixed( 2 )
      },

      firmados( ){

      	let trabajadores = this.filterTrabajadores.length
      	let firmados     = this.filterTrabajadores.filter( el => el.estatus ).length

      	return firmados
      },

      faltan( ){

      	let trabajadores = this.filterTrabajadores.length
      	let firmados     = this.filterTrabajadores.filter( el => el.estatus ).length

      	return trabajadores - firmados
      },

      empleadosTotal( ){

      	let trabajadores = this.filterTrabajadores.length

      	return trabajadores
      },

      tamanioPantalla () {
        return this.$vuetify.breakpoint.height -185
      },

      width(){
        return this.$vuetify.breakpoint.width
      },

      height(){
        return this.$vuetify.breakpoint.height - 250
      }

    },

    watch: {
      dialog_agregar (val) {
        val || this.close()
      },
      dialog_editar (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {

      this.url = axios.defaults.baseURL  + 'comprobantes-imss/'
      await this.initialize()
      await this.getPlanteles()
      await this.getPuestos()
    },

    methods: {
      initialize () {  
        this.cargar = true
        this.trabajadorERP = []
        return this.$http.get('aguinaldo.calculo').then(response=>{
          this.trabajadorERP = response.data

          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      verDocumentos( item ){
        this.reglamento = item.reglamento
        this.descriptor = item.descriptor
        this.misionvision = item.misionvision
        this.confidencialidad = item.confidencialidad
        this.dialogVerDocumentos = true
      },

      getUsuarios( ){
        this.cargar = true
        this.usuarios = []
        this.$http.get('usuarios.all.erp').then(response=>{
          this.usuarios = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPerfiles () {
      	this.cargar = true
        this.perfiles = []
        return this.$http.get('usuarios.erp.get.perfilesERP').then(response=>{
        	this.perfiles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPuestos () {
        this.puestos = []
        this.$http.get('puestos.all').then(response=>{
          this.cargar = false
          this.puestos = response.data
          // Traer los puestos
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.horarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        console.log( this.editedItem  )
        this.dialog_editar = true
      },

      deleteItem (item) {
        this.editedIndex = this.horarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('horarios.eliminar/' + this.editedItem.id_horario, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close() {
        this.dialog_editar = false
        this.dialog_agregar = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.baseItem)
          this.defaultItem = Object.assign({}, this.baseItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.baseItem)
          this.editedIndex = -1
        })
      },

      closeDialog () {
        this.dialog = false
      },

      exportarDataExcel( ){

        // const alumnos = this.filterTrabajadores.map(({ id_trabajador, nombre_completo, puesto, plantel, sueldo_mensual, sueldo_dia, sueldo_hora, factor }) => ({ id_trabajador, nombre_completo, puesto, plantel, sueldo_mensual, sueldo_dia, sueldo_hora, factor }));

        // this.exportExcel( alumnos, 'Trabajadores')
        this.exportExcel( this.filterTrabajadores, 'Trabajadores')

      },

      format( valor ){
	      return valor.toLocaleString('es-MX', {
	        style: 'currency',
	        currency: 'MXN'
	      });
	    },

	    verComprobante( item ){
        this.editedItem = Object.assign({}, item)
        this.dialogPDF = true
	    }
    },
  }
</script>

